
.icon-plus {
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        background-color: currentColor;
    }
    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &.icon--size {
        &-xx-small {
            width: 16px;
            height: 16px;
            &::before,
            &::after {
                width: 8px;
                height: 1px;
            }
        }
        &-x-small {
            width: 18px;
            height: 18px;
            &::before,
            &::after {
                width: 10px;
                height: 2px;
            }
        }
        &-small {
            width: 20px;
            height: 20px;
            &::before,
            &::after {
                width: 12px;
                height: 2px;
            }
        }
        &-default {
            width: 24px;
            height: 24px;
            &::before,
            &::after {
                width: 14px;
                height: 2px;
            }
        }
        &-large {
            width: 26px;
            height: 26px;
            &::before,
            &::after {
                width: 16px;
                height: 2px;
            }
        }
        &-x-large {
            width: 28px;
            height: 28px;
            &::before,
            &::after {
                width: 18px;
                height: 2px;
            }
        }
        &-xx-large {
            width: 30px;
            height: 30px;
            &::before,
            &::after {
                width: 20px;
                height: 3px;
            }
        }
    }
}
