
::v-deep {
    .sitemap {
        &__contents {
            background-color: #fff;
            .container {
                display: flex;
                align-items: center;
            }
        }
    }

    .sitemap-menu {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        margin: calc(var(--grid-gutter-xl) * -1);
        &__item {
            display: flex;
            align-items: center;
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            padding: var(--grid-gutter-xl);
            > .sitemap-menu__sub {
                display: flex;
            }
        }
        &__link {
            position: relative;
            display: inline-block;
            font-size: 6rem;
            font-weight: 700;
            color: var(--v-grey-darken4);
            margin-right: 48px;
            transition: all ease-out 0.2s;
            &:hover {
                color: rgba(0, 0, 0, 0);
                -webkit-text-stroke: 1px var(--v-grey-darken4);
            }
        }
        &__sub-item {
            position: relative;
            padding: var(--grid-gutter);
        }
        &__sub {
            display: none;
            flex-wrap: wrap;
            flex: 1 1 auto;
            margin: calc(var(--grid-gutter-xl) * -1);
        }
        &__sub-link {
            display: inline-block;
            font-size: 1.8rem;
            font-weight: 500;
            color: var(--v-grey-lighten1);
            transition: all ease-out 0.2s;
            &:hover {
                color: var(--v-grey-darken4);
            }
        }
        &__arrow {
            display: none;
        }
    }
}
