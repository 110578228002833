
.header-body {
    height: var(--header-body-height);
    -webkit-transition: all ease-out .2s; -ms-transition: all ease-out .2s; transition: all ease-out .2s;
    .container {
        display: flex;
        align-content: center;
        height: 100%;
    }
}
