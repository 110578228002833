
.lnb {
    &::v-deep {
        position: absolute;
        top: var(--header-body-height);
        left: 50%;
        z-index: 4;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        min-width: 200px;
        width: 100%;
        height: 0;
        text-align: center;
        background-color: rgba(255, 255, 255, 1);
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        > li {
            > a {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: var(--lnb-font-size);
                font-weight: var(--lnb-font-weight);
                color: var(--v-grey-base);
                padding: var(--lnb-padding-y) var(--lnb-padding-x);
                -webkit-transition: all ease-out 0.2s;
                -ms-transition: all ease-out 0.2s;
                transition: all ease-out 0.2s;
            }
        }
    }

    // empty
    &--empty {
        background-color: transparent !important;
        border-color: transparent !important;
    }
}
.gnb__item {
    &.active {
        .lnb {
            height: auto;
            opacity: 1;
            visibility: visible;
        }
    }
}
