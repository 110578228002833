
::v-deep {
    .sitemap {
        &__contents {
            position: absolute;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            max-width: var(--header-container);
            padding: 0;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(100%);
                display: block;
                width: 100vw;
                height: 100vh;
                background-color: #fff;
            }
            .container {
                max-width: 500px;
                display: flex;
                align-items: center;
                background-color: #fff;
                padding: var(--header-height) var(--container-gutter) 60px;
                margin-right: 0;
            }
        }
    }

    .sitemap-menu {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        margin: calc(var(--grid-gutter-lg) * -1);
        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            padding: var(--grid-gutter-lg);
        }
        &__link {
            position: relative;
            display: inline-block;
            font-size: 4rem;
            font-weight: 700;
            color: var(--v-grey-lighten1);
            transition: all ease-out 0.2s;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                display: block;
                width: 12px;
                height: 12px;
                background-color: var(--v-primary-base);
                border-radius: 100%;
                opacity: 0;
                visibility: hidden;
            }
            &:hover {
                color: var(--v-grey-darken4);
                padding-left: 28px;
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &__sub,
        &__arrow {
            display: none;
        }
    }
}
