
.mobile-header {
    &-head,
    &-body,
    &-foot {
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    // 스크롤시
    &.scroll-active {
        ::v-deep {
            .mobile-header {
                &-head,
                &-body,
                &-foot {
                    background-color: rgba(255, 255, 255, 0.8) !important;
                }
                &-body {
                    border-bottom: 1px solid rgba(221, 221, 221, 0.6) !important;
                }
            }
        }
    }
}
