
.app--main::v-deep {
    #contents {
        padding-top: 0 !important;
    }
}

.main-visual {
    display: flex;
    align-items: center;
    height: 480px;
    background-image: -webkit-linear-gradient(to right, #f7f7f8, #c5c8f1);
    background-image: -ms-linear-gradient(to right, #f7f7f8, #c5c8f1);
    background-image: linear-gradient(to right, #f7f7f8, #c5c8f1);
    background-color: #c5c8f1;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        height: 340px;
    }
}
@media (min-width: 1024px) {
    .main-visual {
        height: 600px;
    }
}
@media (min-width: 1200px) {
}
