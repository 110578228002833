
.footer-head--basic {
    padding: 14px 0;
    border-top: 1px solid var(--v-grey-lighten4);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-head--basic {
        padding: 20px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
