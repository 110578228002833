
.header {
    // scroll-active
    &.scroll-active.header--fixed {
        .header {
            &-head,
            &-body,
            &-foot {
                background-color: rgba(255, 255, 255, 0.7) !important;
            }
        }
    }
}
