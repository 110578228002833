
.v-tab--filled {
    background-color: transparent !important;
    &.v-tab{
        &--active {
            color: #fff !important;
            background-color: var(--v-primary-base) !important;
        }
    }
}
