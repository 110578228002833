
::v-deep {
    .gnb--underline {
        .gnb {
            &__item {
                &.active,
                &:hover {
                    .gnb__link {
                        font-weight: 700;
                        color: var(--v-grey-darken4);
                        &::before {
                            width: calc(100% - (var(--gnb-padding-x) * 2));
                            opacity: 1;
                        }
                    }
                }
            }
            &__link {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    -webkit-transform: translate(-50%, 0);
                    -ms-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                    display: block;
                    width: 0;
                    height: 4px;
                    background-color: var(--v-primary-base);
                    opacity: 0;
                    -webkit-transition: all ease-out 0.2s;
                    -ms-transition: all ease-out 0.2s;
                    transition: all ease-out 0.2s;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
